import React from 'react'
import { convertNum, number2text } from '../utils/shared/method'
import '../scss/PrintProof.scss'

export class PrintProof extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="container">
        <div className="header">
          <div>
            <p className="p-center title">電子發票證明聯</p>
            <p className="p-center title-date">
              {this.props.printInfo.invoiceDate.replaceAll('/', '-')}
            </p>
          </div>
          <div>
            <div className="flex-block">
              <p>發票號碼：{this.props.printInfo.invoiceNo}</p>
              <p>格&emsp;&emsp;式：25</p>
            </div>
            <div>
              <p>買&emsp;&emsp;方：{this.props.printInfo.invoice?.buyer_name}</p>
            </div>
            <div>
              <p>統一編號：{this.props.printInfo.vatNo}</p>
            </div>
            <div className="flex-block">
              <p>地&emsp;&emsp;址：</p>
              <p>第 1 頁/共 1 頁</p>
            </div>
          </div>
        </div>

        <table className="invoice">
          <colgroup>
            <col className="colWidth" />
            <col className="colWidth" />
            <col className="colWidth" />
            <col className="colWidth" />
            <col className="colWidth" />
            <col className="colWidth" />
            <col className="colWidth" />
            <col className="colWidth" />
            <col className="colWidth" />
            <col />
            <col />
            <col />
          </colgroup>

          <thead>
            <tr>
              <th colSpan="3" className="p-center">
                品名
              </th>
              <th colSpan="2" className="p-center">
                數量
              </th>
              <th colSpan="2" className="p-center">
                單價
              </th>
              <th colSpan="2" className="p-center">
                金額
              </th>
              <th colSpan="3" className="p-center">
                備註
              </th>
            </tr>
          </thead>

          <tfoot>
            <tr className="food_line">
              <td colSpan="7">銷售額合計</td>
              <td colSpan="2" className="p-right">
                {convertNum(
                  this.props.printInfo.invoice?.total_amount - this.props.printInfo.invoice?.tax_amount
                )}
              </td>
              <td colSpan="3">營業人蓋統一發票專用章</td>
            </tr>

            <tr className="food_line">
              <td className="p-center">營業稅</td>
              <td className="p-center">應稅</td>
              <td className="p-center">{this.props.printInfo.invoice?.tax_amount !== 0 ? 'V' : ''}</td>
              <td className="p-center">零稅率</td>
              <td className="p-center"></td>
              <td className="p-center">免稅</td>
              <td className="p-center">{this.props.printInfo.invoice?.tax_amount === 0 ? 'V' : ''}</td>
              <td colSpan="2" className="p-right">
                {convertNum(this.props.printInfo.invoice?.tax_amount)}
              </td>
              <td colSpan="3" rowSpan="3">
                <div>賣&emsp;&emsp;方：{this.props.printInfo.invoice?.seller_name}</div>
                <div>統一編號：{this.props.printInfo.invoice?.seller_idno}</div>
                <div>地&emsp;&emsp;址：320 桃園市中壢區石頭里中正路 95 號 4 樓</div>
              </td>
            </tr>

            <tr className="food_line">
              <td colSpan="7">總計</td>
              <td colSpan="2" className="p-right">
                {convertNum(this.props.printInfo.invoice?.total_amount)}
              </td>
            </tr>

            <tr className="food_line">
              <td colSpan="9">
                <div className="flex-block">
                  <p>總計新台幣(中文大寫)</p>
                  <p>{number2text(this.props.printInfo.invoice?.total_amount)}</p>
                </div>
              </td>
            </tr>
          </tfoot>

          <tbody>
            {this.props.printInfo.sale?.sale_items?.map((item, i) => (
              <tr className="invoice_line noBorder" key={i}>
                <td colSpan="3">{item.product_name}</td>
                <td colSpan="2" className="p-right">
                  {item.qty}
                </td>
                <td colSpan="2" className="p-right">
                  {convertNum(item.selling_price, 'dp')}
                </td>
                <td colSpan="2" className="p-right">
                  {convertNum(item.amount)}
                </td>
                <td colSpan="3"></td>
              </tr>
            ))}

            <tr className="noBorder">
              <td colSpan="3"></td>
              <td colSpan="2"></td>
              <td colSpan="2"></td>
              <td colSpan="2"></td>
              <td colSpan="3"></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
