import moment from 'moment'

export const isEmpty = (value) => {
  return !value || value.length === 0 ? true : false
}

// 平台對應代號
export const judgePlatform = (platform) => {
  let code = ''

  switch (platform) {
    case '蝦皮購物':
      code = 'shopee'
      break
    case '松果購物':
      code = 'pcone'
      break
    case '樂天市場':
      code = 'rakuten'
      break      
    default:
      code = '91'
      break
  }

  return code
}

// 當月 4 日以後，1 日前所開立的發票，不可修改統編
export const judgeDate = (invoiceDate) => {
  const current = moment()
  const currDay = current.format('DD')
  const currMon = current.format('MM')
  const currMonStart = current.date(1).format('YYYY/MM/DD')
  const invDate = invoiceDate.split('/')

  // 先判斷發票日期
  if (invoiceDate >= currMonStart) {
    return false // can edit
  } else {
    // 是否超過4日，並且是上個月的發票
    const oneMon = diffMon(currMon, invDate[1]) === 1 || diffMon(currMon, invDate[1]) === 11
    if (parseInt(currDay) < 4 && oneMon) {
      return false // can edit
    }
  }

  return true
}

export const diffMon = (a, b) => {
  return Math.abs(parseInt(a) - parseInt(b))
}

// 百分位符號
export const convertNum = (x, dp = null) => {
  if (x && !dp) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else if (x) {
    let newStr = parseFloat(x).toFixed(3)
    return newStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return '0'
  }
}

// 西元轉換民國
export const convertYear = (y) => {
  return parseInt(y) - 1911
}

// 月份對應期間
export const fitMonScope = (m) => {
  let scope = '01-02'

  switch (m) {
    case '01':
    case '02':
      scope = '01-02'
      break
    case '03':
    case '04':
      scope = '03-04'
      break
    case '05':
    case '06':
      scope = '05-06'
      break
    case '07':
    case '08':
      scope = '07-08'
      break
    case '09':
    case '10':
      scope = '09-10'
      break
    case '11':
    case '12':
      scope = '11-12'
      break
    default:
      break
  }

  return scope
}

// 阿拉伯數字轉中文大寫
export const number2text = (number, type = 'upper') => {
  // 配置
  const configs = {
    lower: {
      num: ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'],
      unit: ['', '十', '百', '千'],
      level: ['', '萬', '億'],
    },
    upper: {
      num: ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'],
      unit: ['', '拾', '佰', '仟'],
      level: ['', '萬', '億'],
    },
    decimal: {
      unit: ['分', '角'],
    },
    maxNumber: 999999999999.99,
  }

  // 過濾不合法參數
  if (Number(number) > configs.maxNumber) {
    return false
  }

  const conf = configs[type]
  const numbers = String(Number(number).toFixed(2)).split('.')
  const integer = numbers[0].split('')
  const decimal = Number(numbers[1]) === 0 ? [] : numbers[1].split('')

  // 四位分級
  const levels = integer.reverse().reduce((pre, item, idx) => {
    let level = pre[0] && pre[0].length < 4 ? pre[0] : []
    let value = item === '0' ? conf.num[item] : conf.num[item] + conf.unit[idx % 4]
    level.unshift(value)

    if (level.length === 1) {
      pre.unshift(level)
    } else {
      pre[0] = level
    }

    return pre
  }, [])

  // 整數
  const _integer = levels.reduce((pre, item, idx) => {
    let _level = conf.level[levels.length - idx - 1]
    let _item = item.join('').replace(/(零)\1+/g, '$1') // 連續多個零字的部分設置為單個零字

    // 如果這一級只有一個零字，則去掉這級
    if (_item === '零') {
      _item = ''
      _level = ''

      // 否則如果末尾為零字，則去掉這個零字
    } else if (_item[_item.length - 1] === '零') {
      _item = _item.slice(0, _item.length - 1)
    }

    return pre + _item + _level
  }, '')

  // 小數
  let _decimal = decimal
    .map((item, idx) => {
      const unit = configs.decimal.unit
      const _unit = item !== '0' ? unit[unit.length - idx - 1] : ''

      return `${conf.num[item]}${_unit}`
    })
    .join('')

  // 如果是整數，則補整字
  return `${_integer}元` + (_decimal || '整')
}
