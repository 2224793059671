import React, { useRef, useState } from 'react'
import { convertYear, fitMonScope, convertNum } from '../utils/shared/method'
import '../scss/PrintNotice.scss'

export class PrintNotice extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="contain">
        <div className="stone">墊腳石購物網</div>
        <div className="notice">發票開立通知</div>
        <div className="p-center warn">*非正式發票，不可兌獎*</div>
        <div className="notice">
          {convertYear(this.props.printInfo.invoiceDate.slice(0, 4))} 年{' '}
          {fitMonScope(this.props.printInfo.invoiceDate.slice(5, 7))} 月
        </div>
        <div className="notice invoiceNo">
          {this.props.printInfo.invoiceNo.slice(0, 2)}-{this.props.printInfo.invoiceNo.slice(2)}
        </div>

        <div>{this.props.printInfo.invoiceDate.replaceAll('/', '-')}</div>
        <div className="flex-block">
          {/* <div>隨機碼 0297</div> */}
          <div>賣方 54947643</div>
          <div>總計 {convertNum(this.props.printInfo.invoice?.total_amount)}</div>
        </div>

        <div className="notice invoiceNo">交易明細</div>

        <div className="itemContent">
          <div>品名</div>
          <div className="flex-block">
            <div>單價 * 數量</div>
            <div>小計</div>
          </div>
        </div>

        {this.props.printInfo.sale?.sale_items?.map((item, i) => (
          <div className="itemContent" key={i}>
            <div>{item.product_name}</div>
            <div className="flex-block">
              <div>
                ${convertNum(item.selling_price)} * {item.qty}
              </div>
              <div>${convertNum(item.amount)}</div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}
