import axios from 'axios'
import setupInterceptors from './axiosInterceptor'

const baseURL = 'https://invoice.tcsb.com.tw'

const apiClient = axios.create({
  baseURL,
  timeout: 5000,
})

const configForm = {
  headers: {
    // 'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
  },
}

setupInterceptors(apiClient)

// Post Form
export const sendFormData = (data) => apiClient.post(`/api/v1/invoice/q`, data, configForm)

export const sendVatNo = (data) => apiClient.post(`/api/v1/invoice/vatNo`, data, configForm)
