import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, positions } from 'react-alert'
import AlertCustom from './utils/AlertCustom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './custom.scss'

const options = {
  timeout: 0,
  position: positions.TOP_CENTER,
  containerStyle: {
    zIndex: 2000,
  },
}

const Root = () => (
  <Provider template={AlertCustom} {...options}>
    <App />
  </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
