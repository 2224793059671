import React from 'react'

export default function ErrorMessage({ error }) {
  if (error) {
    switch (error.type) {
      case 'required':
        return <p className="col-sm-4 text_alert">必填</p>
      case 'minLength':
        return <p className="col-sm-4 text_alert">請填入三位數</p>
      case 'maxLength':
        return <p className="col-sm-4 text_alert">請勿超過三位數</p>
      case 'pattern':
        return <p className="col-sm-4 text_alert">請填入英文字符與數字</p>
      // case 'validate':
      //   return <p>XXX</p>
      default:
        return null
    }
  }

  return null
}
