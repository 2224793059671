import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { useAlert } from 'react-alert'
import ErrorMessage from './utils/errorMessage/app'
import Regulation from './components/Regulation'
import InvoiceModal from './utils/InvoiceModal'
import { sendFormData } from './api'
import logo from './logo.png'
import './App.scss'

function App() {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    setValue,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur' })
  const reRef = useRef()
  const reactAlert = useAlert()
  const [repData, setRepData] = useState() // API 回傳資料儲存
  const [show, setShow] = useState(false) // Modal 開窗控管

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // Extract token from URL when the component mounts
  useEffect( () =>  {
    async function fetchData(uuid) {
      try {
        handleShow()
        const rep = await sendFormData({uuid})
        setRepData(rep.data)
        setValue('platform', rep.data.data?.invoice?.platform)
        setValue('orderNo', rep.data.data?.orderNo)
        setValue('phone', rep.data.data?.sale?.recipient_phone?.slice(-3))
      } catch (error) {
        if (error.message.includes('timeout')) {
          reactAlert.show(<div>連線逾時，請重新查詢</div>)
        }

        handleClose()
      } 
    }

    const params = window.location.href.split('?')
    if(params.length >= 2) {
      const urlParams = new URLSearchParams(`${window.location.href.split('?')[1]}`);
      const uuid = urlParams.get('uuid');
  
      if (uuid) {
        console.log('UUID from URL:', uuid);
        fetchData(uuid);
      }
    }

  }, []); 


  const onSubmit = async (formData) => {
    setRepData(null) // Reset
    handleShow()

    const token = await reRef.current.getValue()
    reRef.current.reset()

    if (!token) {
      return setRepData({ message: '請勾選我不是機器人' })
    }

    const reqData = { ...formData, 'g-recaptcha-response': token }

    try {
      const rep = await sendFormData(reqData)
      setRepData(rep.data)
    } catch (error) {
      if (error.message.includes('timeout')) {
        reactAlert.show(<div>連線逾時，請重新查詢</div>)
      }

      handleClose()
    }
  }

  return (
    <div className="content">
      <div className="box-wrap">
        <img src={logo} className="App-logo" alt="logo" />
      </div>

      <h1 className="title">發票查詢</h1>

      <div className="text-center">
        <span className="env_red">*</span>
        <span> 為必填欄位，不能為空白</span>
      </div>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form_group">
          <label htmlFor="platform" className="col-md-4 control_label">
            <span className="env_red">*</span>
            <span> 平台 :</span>
          </label>

          <select
            id="platform"
            className="col-md-4 form_control"
            name="platform"
            ref={register({ required: true })}
          >
            <option value="">選擇...</option>
            {/* <option value="momo">momo</option> */}
            <option value="91">墊腳石購物網(www.tcsb.com.tw)</option>
            <option value="shopee">蝦皮購物</option>
            <option value="pcone">松果購物</option>
            <option value="rakuten">樂天市場</option>
            <option value="momo">momo</option>
          </select>

          <ErrorMessage error={errors.platform} />
        </div>

        <div className="form_group">
          <label htmlFor="orderNo" className="col-md-4 control_label">
            <span className="env_red">*</span>
            <span> 完整訂單號碼 :</span>
          </label>

          <input
            id="orderNo"
            className="col-md-4 form_control"
            name="orderNo"
            ref={register({ required: true, pattern: /^[A-Za-z0-9\-]+$/ })}
          />

          <ErrorMessage error={errors.orderNo} />
        </div>

        <div className="form_group">
          <label htmlFor="phone" className="col-md-4 control_label">
            <span className="env_red">*</span>
            <span> 收件人手機末三碼 :</span>
          </label>

          <input
            id="phone"
            className="col-md-4 form_control"
            name="phone"
            ref={register({ required: true, minLength: 3, maxLength: 3 })}
          />

          <ErrorMessage error={errors.phone} />
        </div>

        <div className="form_group">
          <label className="col-md-offset-2 col-md-5">
            <ReCAPTCHA sitekey={'6LevwmYaAAAAAPKekRfuYft9i3WcxLFyPqpDjLCr'} ref={reRef} />
          </label>
        </div>

        <div className="form_group">
          <div className="col-md-offset-2 col-md-5">
            <input className="btn btn-primary" disabled={isSubmitting} type="submit" />
          </div>
        </div>
      </form>

      <Regulation />
      <InvoiceModal values={repData} show={show} handleClose={handleClose}></InvoiceModal>
    </div>
  )
}

export default App
