import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../utils/errorMessage/vatNo'
import { judgePlatform } from '../utils/shared/method'
import { sendVatNo } from '../api'
import '../scss/VatNoForm.scss'

const VatNoForm = ({ invoiceData, handleClose, openForm }) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur' })
  const [repData, setRepData] = useState() // API 回傳資料儲存

  const onSubmitVatNo = async (formData) => {
    // setRepData(null) // Reset
    let platformCode = judgePlatform(invoiceData.platform)

    const reqData = {
      invoiceNo: invoiceData.invoiceNo,
      orderNo: invoiceData.orderNo,
      platform: platformCode,
      ...formData,
    }

    try {
      const repData = await sendVatNo(reqData)

      if (repData.data.status === 0) {
        alert('已成功提出修改，約 10 分鐘後完成重新開立')
      } else {
        alert(repData.data.message)
      }
      // setRepData(rep.data)
    } catch (error) {
      console.log(error)
    }

    openForm(false)
    handleClose()
  }

  const validateEmail = (value) => {
    return /^\S+@\S+$/i.test(value)
  }

  return (
    <form className="vatNoContent" onSubmit={handleSubmit(onSubmitVatNo)}>
      <div className="col-lg-10 vatNoItem">
        <label htmlFor="vatNo" className="col-lg-3">
          <span>統編號碼 :</span>
        </label>

        <input
          id="vatNo"
          className="col-lg-3"
          name="vatNo"
          ref={register({ required: true, minLength: 8, pattern: /^\d+$/ })}
        />

        <ErrorMessage error={errors.vatNo} />
      </div>

      <div className="col-lg-10 vatNoItem">
        <label htmlFor="company" className="col-lg-3">
          <span>公司名稱 :</span>
        </label>

        <input
          id="company"
          className="col-lg-3"
          name="company"
          ref={register({ required: true })}
        />

        <ErrorMessage error={errors.company} />
      </div>

      {/* <div className="col-lg-10 vatNoItem">
        <label htmlFor="email" className="col-lg-3">
          <span>電子郵件 :</span>
        </label>

        <input
          id="email"
          className="col-lg-3"
          name="email"
          ref={register({ required: true, validate: validateEmail })}
        />

        <ErrorMessage error={errors.email} />
      </div> */}

      <div className="col-lg-2 vatNoItem">
        <input className="btn btn-primary" disabled={isSubmitting} type="submit" />
      </div>
    </form>
  )
}

export default VatNoForm
