import React from 'react'

export default function ErrorMessage({ error }) {
  if (error) {
    switch (error.type) {
      case 'required':
        return <p className="col-lg-6 text_alert">必填</p>
      case 'pattern':
        return <p className="col-lg-6 text_alert">請填入正確的信箱格式</p>
      default:
        return null
    }
  }

  return null
}
