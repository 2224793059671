import React from 'react'
import '../scss/AlertCustom.scss'

const AlertCustom = ({ style, options, message, close }) => {
  return (
    <div style={style} className="alert-content">
      {/* {options.type === 'info' && '!'}
        {options.type === 'success' && ':)'}
        {options.type === 'error' && ':('} */}
      <div className="message-content">{message}</div>

      <div className="button-content">
        <button className="alert-btn" onClick={close}>
          確定
        </button>
      </div>
    </div>
  )
}

export default AlertCustom
