import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useAlert } from 'react-alert'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import { isEmpty, judgeDate } from '../utils/shared/method'
import VatNoForm from '../components/VatNoForm'
import EmailForm from '../components/EmailForm'
import { PrintNotice } from '../components/PrintNotice' // class component
import { PrintProof } from '../components/PrintProof' // class component
import '../scss/InvoiceModal.scss'

const InvoiceModal = ({ values, show, handleClose }) => {
  const noticeRef = useRef() // 沒有買受人統編印 PrintNotice
  const proofRef = useRef() // 有買受人統編印 PrintProof
  const reactAlert = useAlert()
  const [openVatNo, setOpenVatNo] = useState(false)
  const [openEmail, setOpenEmail] = useState(false)

  useEffect(() => {
    return () => {
      setOpenVatNo(false)
      setOpenEmail(false)
    }
  }, [values])

  const checkVatNo = () => {
    if (values.data.isVatNo) {
      return reactAlert.show(<div>此發票已開立過統編</div>)
    }

    if (judgeDate(values.data.invoiceDate)) {
      return reactAlert.show(<div>如需要修改統編，請聯繫客服</div>)
    }

    setOpenVatNo((c) => !c)
  }

  const checkEmail = () => {
    setOpenEmail((c) => !c)
  }

  const printNotice = useReactToPrint({
    content: () => noticeRef.current,
  })

  const printProof = useReactToPrint({
    content: () => proofRef.current,
  })

  return (
    <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
      {!values && (
        <Modal.Header closeButton>
          <Modal.Title>查詢中...</Modal.Title>
        </Modal.Header>
      )}

      {values && values.data && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>查詢結果</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="modal_group">
              <div className="col-lg-2 modal_label">
                <span>訂單號碼：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{values.data.orderNo}</span>
              </div>

              <div className="col-lg-2 modal_label">
                <span>開立平台：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{values.data.platform}</span>
              </div>
            </div>

            <div className="modal_group">
              <div className="col-lg-2 modal_label">
                <span>發票號碼：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{values.data.invoiceNo}</span>
              </div>

              <div className="col-lg-2 modal_label">
                <span>發票日期：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{values.data.invoiceDate}</span>
              </div>
            </div>

            <div className="modal_group">
              <div className="col-lg-2 modal_label">
                <span>發票狀態：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{values.data.invoiceStatus}</span>
              </div>

              <div className="col-lg-2 modal_label">
                <span>是否捐贈：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{values.data.invoice?.donated ? '是' : '否'}</span>
              </div>
            </div>

            <div className="modal_group">
              <div className="col-lg-2 modal_label">
                <span>買受人統編：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{isEmpty(values.data.vatNo) ? '' : values.data.vatNo}</span>
              </div>

              <div className="col-lg-2 modal_label">
                <span>買受人名稱：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>{isEmpty(values.data.vatNo) ? '' : values.data.invoice?.buyer_name}</span>
              </div>
            </div>

            <div className="modal_group">
              <div className="col-lg-2 modal_label">
                <span>共通性載具：</span>
              </div>
              <div className="col-lg-2 help_block">
                <span>
                  {isEmpty(values.data.invoice?.carrier_type) ||
                  values.data.invoice?.carrier_type == 3
                    ? ''
                    : values.data.invoice?.carrier_code1}
                </span>
              </div>
            </div>
          </Modal.Body>

          <div className="buttonContent">
            <button className="btn btn-info btnItem" onClick={checkVatNo}>
              修改統編
            </button>

            {/* {values.data.isVatNo && (
              <button className="btn btn-info btnItem" onClick={checkEmail}>
                寄送電子郵件
              </button>
            )} */}

            {values.data.isVatNo ? (
              <button className="btn btn-info btnItem" onClick={printProof}>
                列印證明聯
              </button>
            ) : (
              <button className="btn btn-info btnItem" onClick={printNotice}>
                列印證明聯
              </button>
            )}

            {openVatNo && (
              <VatNoForm
                invoiceData={values.data}
                handleClose={handleClose}
                openForm={setOpenVatNo}
              />
            )}

            {openEmail && (
              <EmailForm
                invoiceData={values.data}
                handleClose={handleClose}
                openForm={setOpenEmail}
              />
            )}
          </div>

          {/* Hide */}
          <div className="print">
            <PrintNotice printInfo={values.data} ref={noticeRef} />
          </div>
          <div className="print">
            <PrintProof printInfo={values.data} ref={proofRef} />
          </div>
        </>
      )}

      {values && !values.data && (
        <Modal.Header closeButton>
          <Modal.Title>{values.message}</Modal.Title>
        </Modal.Header>
      )}
    </Modal>
  )
}

export default InvoiceModal
