import React from 'react'
import '../App.scss'

const Regulation = () => {
  return (
    <div className="form">
      <div className="col-sm-12">
        <h5>電子發票說明</h5>
      </div>

      <div className="col-sm-12" mt={1}>
        <p>
          <span className="text_danger">1.統一發票開立方式</span>
          <br />
          <span className="text_info">
            在墊腳石買東西購物結帳時，完成訂購程序後，本司將於開立電子發票後，將已開立之電子發票通知信或發票證明聯(有開立統編者)透過以下平台網址http://invoice.tcsb.com.tw供您查詢已開立之發票。
          </span>
        </p>

        <p>
          <span className="text_danger">2.中獎時如何索取電子發票？</span>
          <br />
          <span className="text_info">
            發票載具未歸戶：依財政部規定，我們會每兩個月於統一發票開獎時，利用平台自動幫您對獎，凡有中獎，會以電話通知並詢問郵寄地址，屆時以掛號寄出供消費者兌獎。
          </span>
          <br />
          <span className="text_info">
            使用「手機條碼載具」或「自然人憑證載具」，中獎發票將由「財政部電子發票整合平台」進行領獎相關通知及作業。
          </span>
          <br />
          <span className="text_info">
            如已索取紙本發票，則系統不再進行對獎，消費者需依索取之紙本發票自行對獎。
          </span>
        </p>

        <p>
          <span className="text_danger">3.電子發票捐贈後，可以反悔不捐贈嗎？</span>
          <br />
          <span className="text_info">消費者一旦捐贈就不能反悔，其所有權即屬於受贈單位。</span>
          <br />
          <span className="text_info">如有問題，請撥打客服電話03-2713855</span>
          <br />
          <span className="text_info">(周一至周五 09:30~17:30，例假日或國定假日除外)</span>
        </p>

        <p>
          <span className="text_danger">4.發票可以指定日期、開立其它品名或金額嗎？</span>
          <br />
          <span className="text_info">
            本公司發票全面採用自動化系統開立，請恕無法指定開立其它品名、金額，或指定開立日期。且一次出貨對應一張發票，恕無法分別開立多張。
          </span>
        </p>

        <p>
          <span className="text_danger">5.電子發票證明聯，統編有錯或漏開怎麼辦？</span>
          <br />
          <span className="text_info">
            請於發票開立後的隔月3號前進入發票查詢平台補正(系統限得補正乙次)，補正後正確的抬頭與統編屆時將會重新開立，若發票開立後時間已超過隔月3號，恕無法重新協助開立。
          </span>
        </p>
      </div>
    </div>
  )
}

export default Regulation
