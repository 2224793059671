import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../utils/errorMessage/email'
import { judgePlatform } from '../utils/shared/method'
// import { sendEmail } from '../api'
import '../scss/EmailForm.scss'

const EmailForm = ({ invoiceData, handleClose, openForm }) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({ mode: 'onBlur' })
  const [repData, setRepData] = useState() // API 回傳資料儲存

  const onSubmitEmail = async (formData) => {
    let platformCode = judgePlatform(invoiceData.platform)

    const reqData = {
      invoiceNo: invoiceData.invoiceNo,
      orderNo: invoiceData.orderNo,
      vatNo: invoiceData.vatNo,
      platform: platformCode,
      ...formData,
    }
    // const repData = await sendEmail(reqData)
    alert(JSON.stringify(reqData))
    openForm(false)
    handleClose()
  }

  return (
    <form className="emailContent" onSubmit={handleSubmit(onSubmitEmail)}>
      <div className="col-lg-10 emailItem">
        <label htmlFor="email" className="col-lg-3">
          <span>電子郵件 :</span>
        </label>

        <input
          id="email"
          className="col-lg-3"
          name="email"
          ref={register({ required: true, pattern: /^\S+@\S+$/i })}
        />

        <ErrorMessage error={errors.email} />
      </div>

      <div className="col-lg-2 emailItem">
        <input className="btn btn-primary" disabled={isSubmitting} type="submit" />
      </div>
    </form>
  )
}

export default EmailForm
