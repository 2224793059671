// import store from '../store/store'

const setupInterceptors = (axios) => {
  axios.interceptors.request.use(
    (config) => {
      // const {
      //   auth: { authToken },
      // } = store.getState()

      // if (authToken) {
      //   config.headers.Authorization = `Bearer ${authToken}`
      // }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  )

  const errorHandler = (error) => {
    return Promise.reject(error)
  }
  const successHandler = (response) => {
    return response
  }
}

export default setupInterceptors
